import React, { useState } from 'react';

import { BarraPorcentagem } from "@cpnt/BarraPorcentagem/BarraPorcentagem";
import Button from "@cpnt/Button/Button";
import { LabelNavio } from "@cpnt/LabelNavio/LabelNavio";
import { LinhaItem } from "@cpnt/LinhaItem/LinhaItem";
import PortalAcompanhamentosHoldFormCadastro from "@cpnt/Portais/PortaisAcompanhamentos/PortalAcompanhamentosHoldFormCadastro/PortalAcompanhamentosHoldFormCadastro";
import { dataApiParaDataHoraMin, dataApiParaDataMesDiaAno } from "@func/date";
import PortalAcompanhamentosComplementFormCadastro from "@cpnt/Portais/PortaisAcompanhamentos/PortalAcompanhamentosComplementFormCadastro/PortalAcompanhamentosComplementFormCadastro";
import PortalAcompanhamentosEmpresasFormCadastro from "@cpnt/Portais/PortaisAcompanhamentos/PortalAcompanhamentosEmpresasFormCadastro/PortalAcompanhamentosEmpresasFormCadastro";

const DetalhesAcompanhamento = props => {
	const {
		stateDados,
		onInsertHolds,
		onInsertComplements,
		onInsertEmpresas,
		after,
	} = props;

	const [showPortalHoldFormCadastro, setShowPortalHoldFormCadastro] = useState(false);
	const [showPortalComplementFormCadastro, setShowPortalComplementFormCadastro] = useState(false);
	const [showPortalEmpresasFormCadastro, setShowPortalEmpresasFormCadastro] = useState(false);

	const total = () => {
		return (
			<tr style={{backgroundColor: '#EFF9F0'}}>
				<td><strong>Total</strong></td>
				<td>{stateDados.holds_total?.cargo_plan}</td>
				<td>{stateDados.holds_total?.cargo_loaded}</td>
				<td>{stateDados.holds_total?.balance}</td>
			</tr>
		)
	}

	return (
		<>
			<div className="box-txt">
				<h3>{stateDados.vessel}</h3>
				<div>
					<h3 className="margin-b-5">Datas</h3>
					<div className="flex gap-20">
						<div>
							<LinhaItem titulo="ETA" desc={dataApiParaDataMesDiaAno(stateDados.eta)} />
						</div>
						<div>
							<LinhaItem titulo="ETB" desc={dataApiParaDataMesDiaAno(stateDados.etb)} />
						</div>
						<div>
							<LinhaItem titulo="ETS" desc={dataApiParaDataMesDiaAno(stateDados.ets)} />
						</div>
					</div>
				</div>

				<h3 className="margin-b-5">Holds</h3>
				<table className="tabela">
					<thead>
						<th className="">
							Holds
						</th>
						<th className="col-min-7">
							Cargo plan
						</th>
						<th className="">
							{stateDados?.complement?.operation === 'discharged' ? 'Discharged' : 'TTL Loaded'}
						</th>
						<th className="col-min-9">
							Balance to go
						</th>
					</thead>
					<tbody>
						{stateDados.holds && stateDados.holds.length ? stateDados.holds.map((val, key) => {
							return (
								<tr key={key}>
									<td data-titulo="Holds">
										<div className="td-wrap">
											{val.hold || '-'}
										</div>
									</td>
									<td data-titulo="Cargo plan">
										<div className="td-wrap">
											{val.cargo_plan || '-'}
										</div>
									</td>
									<td data-titulo="TTL Loaded">
										<div className="td-wrap">
											<div className="flex flex-center-v no-wrap gap-20 flex-between">
												<div>{val.cargo_loaded || '-'}</div>
												<BarraPorcentagem porcentagem={val.percent} width="140px" />
											</div>
										</div>
									</td>
									<td data-titulo="Balance to go">
										<div className="td-wrap">
											{val.balance || '-'}
										</div>
									</td>
								</tr>
							)
						}) : (
							<tr>
								<td colSpan={4}>
									<h3>Nenhuma informação encontrada</h3>
								</td>
							</tr>
						)}

						{stateDados.holds && stateDados.holds.length ? (
							total(stateDados)
						) : null}
					</tbody>
				</table>
				<div className="flex flex-end margin-t-10">
					<Button
						_={{
							value: 'Editar holds',
							className: 'btn btn-default btn-min-alt padding-h-10',
							onClick: e => setShowPortalHoldFormCadastro(true),
						}}
					/>
				</div>

				<div className="border-t margin-t-10 padding-t-10">
					<h3 className="margin-t-0 margin-b-5">Complements</h3>
					<div className="flex gap-20">
						<div>
							<LinhaItem titulo="ETA Time" desc={stateDados.complement.eta_time || '(não informado)'} />
						</div>
						<div>
							<LinhaItem titulo="ETB Time" desc={stateDados.complement.etb_time || '(não informado)'} />
						</div>
						<div>
							<LinhaItem titulo="ETS Time" desc={stateDados.complement.ets_time || '(não informado)'} />
						</div>
					</div>
					<div className="flex gap-20 margin-t-5">
						<div>
							<LinhaItem titulo="Tipo" desc={stateDados.complement.operation || 'Carga'} />
						</div>
						<div>
							<LinhaItem titulo="Status" desc={stateDados.complement.status && stateDados.complement.status !== '' ? `${stateDados.complement.status} (manual)` : `${stateDados.status} (automático)`} />
						</div>
						<div>
							<LinhaItem titulo="Última atualização" desc={dataApiParaDataHoraMin(stateDados.complement.last_update) || '(não informado)'} />
						</div>
					</div>
				</div>
				<div className="flex flex-end margin-t-10">
					<Button
						_={{
							value: 'Editar complemento',
							className: 'btn btn-default btn-min-alt padding-h-10',
							onClick: e => setShowPortalComplementFormCadastro(true),
						}}
					/>
				</div>

				<div className="border-t margin-t-10 padding-t-10">
					<h3 className="margin-t-0 margin-b-5">Empresas com acesso</h3>
					<div className="flex gap-10">
						{stateDados.extra_companies && stateDados.extra_companies.length ? (
							stateDados.extra_companies.map((val, key) => {
								return (
									<div className="tag" key={key}>
										{val.company_name}
									</div>
								)
							})
						) : (
							<div>Nenhuma empresa adicionada.</div>
						)}
					</div>
				</div>
				<div className="flex flex-end margin-t-10">
					<Button
						_={{
							value: 'Editar empresas',
							className: 'btn btn-default btn-min-alt padding-h-10',
							onClick: e => setShowPortalEmpresasFormCadastro(true),
						}}
					/>
				</div>

				<div className="border-t margin-t-10 padding-t-10">
					<LinhaItem titulo="Agent" desc={stateDados.agent} />
					<LinhaItem titulo="Vessel" desc={stateDados.port} />
					<LinhaItem titulo="Charterer" desc={stateDados.charterer} />
					<LinhaItem titulo="Destination" desc={stateDados.destination} />
					<LinhaItem titulo="Cargo type" desc={stateDados.cargo_type} />
					<LinhaItem titulo="Port" desc={stateDados.port} />
					<LinhaItem titulo="Status" desc={<LabelNavio {...stateDados} />} />
					<LinhaItem titulo="Cargoes" desc={stateDados.cargoes} />
					<LinhaItem titulo="Shippers" desc={stateDados.shippers} />
					<LinhaItem titulo="Type" desc={stateDados.type} />
					<LinhaItem titulo="Berth" desc={stateDados.berth} />
					<LinhaItem titulo="WT" desc={stateDados.wt} />
					<LinhaItem titulo="ID Interno" desc={stateDados.id} />
				</div>
			</div>
			{after}

			<PortalAcompanhamentosHoldFormCadastro
				show={showPortalHoldFormCadastro}
				stateAcompanhamento={stateDados}
				onClose={() => {
					setShowPortalHoldFormCadastro(false);
				}}
				onSuccess={e => {
					onInsertHolds();
					setShowPortalHoldFormCadastro(false);
				}}
			/>

			<PortalAcompanhamentosComplementFormCadastro
				show={showPortalComplementFormCadastro}
				stateComplement={stateDados}
				onClose={() => {
					setShowPortalComplementFormCadastro(false);
				}}
				onSuccess={e => {
					onInsertComplements();
					setShowPortalComplementFormCadastro(false);
				}}
			/>

			<PortalAcompanhamentosEmpresasFormCadastro
				show={showPortalEmpresasFormCadastro}
				stateEmpresas={stateDados}
				onClose={() => {
					setShowPortalEmpresasFormCadastro(false);
				}}
				onSuccess={e => {
					onInsertEmpresas();
					setShowPortalEmpresasFormCadastro(false);
				}}
			/>
		</>

	);
};

export { DetalhesAcompanhamento };

import React from 'react';

// css
import c from './FormComplement.module.css';

// Routes
import Input from "@cpnt/Input/Input";
import { Select } from "@cpnt/Select/Select";
import { STATUS_VESSELS } from "@config/objetos";

const FormComplement = props => {

	const {
		onSubmit,
		stateDados,
		setStateDados,
		after,
	} = props;

	return (
		<>
			<form onSubmit={onSubmit}>
				<div className="wrap">
					<div className="box-txt">
						<div className={`${c.inputs}`}>
							<div className="">
								<Input
									label="ETA Time"
									_={{
										value: stateDados.eta_time,
										onChange: e => {
											setStateDados({
												...stateDados,
												eta_time: e.target.value,
											})
										},
										mask: '99:99',
									}}
									debounce
								/>
							</div>
							<div className="">
								<Input
									label="ETB Time"
									_={{
										value: stateDados.etb_time,
										onChange: e => {
											setStateDados({
												...stateDados,
												etb_time: e.target.value,
											})
										},
										mask: '99:99',
									}}
									debounce
								/>
							</div>
							<div className="">
								<Input
									label="ETS Time"
									_={{
										value: stateDados.ets_time,
										onChange: e => {
											setStateDados({
												...stateDados,
												ets_time: e.target.value,
											})
										},
										mask: '99:99',
									}}
									debounce
								/>
							</div>
							<div className={`${c.tipo}`}>
								<Select
									label="Tipo"
									_={{
										value: stateDados.operation,
										onChange: e => {
											setStateDados({
												...stateDados,
												operation: e.value,
											});
										},
										options: [
											{label: 'Carga', value: 'loaded'},
											{label: 'Descarga', value: 'discharged'},
										],
									}}
									wrap="flex-1"
								/>
							</div>
							<div className={`${c.status}`}>
								<Select
									label="Status"
									_={{
										value: stateDados.status,
										onChange: e => {
											setStateDados({
												...stateDados,
												status: e.value,
											});
										},
										options: STATUS_VESSELS,
									}}
									wrap="flex-1"
								/>
							</div>
							<div className={`${c.date}`}>
								<Input
									label="Última atualização"
									tip="Formato: Dia/Mês/Ano Hora:Minuto"
									_={{
										value: stateDados.last_update,
										onChange: e => {
											setStateDados({
												...stateDados,
												last_update: e.target.value,
											})
										},
										mask: '99/99/9999 99:99',
									}}
									debounce
								/>
							</div>
						</div>
					</div>
				</div>

				{after}
			</form>
		</>
	);
};

export default FormComplement;